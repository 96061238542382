import React from "react";
import {Spinner} from "reactstrap";

import "./SpinnerPage.scss";

const SpinnerPage = () => {
    return (
        <div className="spinner-page">
            <div>
                <div className="spinner-container ">
                    <Spinner className="spinner" />
                </div>
            </div>
        </div>
    );
}

export default SpinnerPage;