const quotes = [
    "HE’S AS CLUMSY AS HE IS STUPID.",
    "YOU DON’T KNOW THE POWER OF THE DARK SIDE! I MUST OBEY MY MASTER.",
    "THIS WILL BE A DAY LONG REMEMBERED.",
    "I AM ALTERING THE DEAL. PRAY I DON’T ALTER IT ANY FURTHER.",
    "BE CAREFUL NOT TO CHOKE ON YOUR ASPIRATIONS.",
    "YOU HAVE CONTROLLED YOUR FEAR. NOW, RELEASE YOUR ANGER.",
    "ONLY YOUR HATRED CAN DESTROY ME.",
    "THEN YOU WILL DIE BRAVER THAN MOST.",
    "WHEN I LEFT YOU, I WAS BUT THE LEARNER. NOW I AM THE MASTER.",
    "THE ABILITY TO DESTROY A PLANET IS INSIGNIFICANT NEXT TO THE POWER OF THE FORCE.",
    "NO … I AM YOUR FATHER…",
    "I FIND YOUR LACK OF FAITH DISTURBING.",
    "ANAKIN SKYWALKER WAS WEAK. I DESTROYED HIM.",
    "ASTEROIDS DO NOT CONCERN ME, ADMIRAL.",
    "DON’T FAIL ME AGAIN, ADMIRAL.",
    "FROM MY POINT OF VIEW, THE JEDI ARE EVIL.",
    "HE WILL JOIN US OR DIE, MY MASTER.",
    "HE WILL NOT BE PERMANENTLY DAMAGED.",
    "THE EMPEROR WILL SHOW YOU THE TRUE NATURE OF THE FORCE. HE IS YOUR MASTER NOW.",
    "IT IS YOUR DESTINY. JOIN ME, AND TOGETHER WE CAN RULE THE GALAXY AS FATHER AND SON.",
    "THERE IS NO ESCAPE! DON'T MAKE ME DESTROY YOU!",
    "THE EMPEROR IS NOT AS FORGIVING AS I AM.",
    "YOU ARE UNWISE TO LOWER YOUR DEFENSES!",
    "IF YOU WILL NOT TURN TO THE DARK SIDE, THEN PERHAPS SHE WILL!",
    "IMPRESSIVE. MOST IMPRESSIVE.",
    "YOU HAVE FAILED ME FOR THE LAST TIME.",
    "PERHAPS I CAN FIND NEW WAYS TO MOTIVATE THEM.",
    "ALL TOO EASY.",
    "I HAVE BROUGHT PEACE, FREEDOM, JUSTICE, AND SECURITY TO MY NEW EMPIRE."
];

export default quotes;