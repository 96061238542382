// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Initialize Firebase
const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));

const auth = getAuth(app);
auth.useDeviceLanguage();

export { auth, GoogleAuthProvider };


