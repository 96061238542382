import React from 'react';

import './Proficiencies.scss';

const proficiencyTable = {
    weapon: {
        SIMPLE: "Simple Weapons",
        PISTOLS: "Pistols",
        RIFLES: "Rifles",
        LIGHTSABERS: "Lightsabers",
        HEAVY: "Heavy Weapons",
        ADVANCED: "Advanced Weapons",
        DISPLAY: "WEAPONS"
    },
    armor: {
        LIGHT: "Light",
        MEDIUM: "Medium",
        HEAVY: "Heavy",
        DISPLAY: "ARMOR"
    },
    language: {
        DISPLAY: "LANGUAGES"
    }
}

const ProficiencyBlock = (props) => {
    let profs = props.profs[props.profType];
    const label = proficiencyTable[props.profType].DISPLAY;

    return (
        <div className="proficiency">
            <span className="prof-label">{label}</span>
            <span className="prof-list">{profs?.join(", ")}</span>
        </div>
    );
}

const Proficiencies = (props) => {
    const { proficiencies } = props;
    return (
        <div className="proficiency-container box">
            <ProficiencyBlock profType="armor" profs={proficiencies}/>
            <ProficiencyBlock profType="weapon" profs={proficiencies}/>
            <ProficiencyBlock profType="language" profs={proficiencies}/>
        </div>
    );
}

export default Proficiencies;