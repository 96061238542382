import React from 'react';

import { DamageThreshold } from "./index";

import './Defenses.scss';

const DefenseScore = (props) => {
    const { defense, scores } = props;
    const { score, deniedDexScore } = scores;
    let deniedDex = null;
    if (deniedDexScore !== null && deniedDexScore !== undefined){
        deniedDex = (
            <div className="flat-footed-container">
                <div className="circle-defense-score">
                    <span className="denied-dex-score">{deniedDexScore}</span>
                    <span className="label">Flat</span>
                </div>
            </div>
        );
    }
    return (
        <div className="score-container">
            <div className="triangle-score image-with-score" >
                <div>
                    <span className="score">{score}</span>
                    <span className="defense">{defense}</span>
                </div>
            </div>
            {deniedDex}
        </div>
    );
}

const Defenses = ({ defenses }) => {
    const defenseObj = {
        Reflex: {
            score: defenses.reflex.score,
            deniedDexScore: defenses.reflexFlat.score
        },
        Fortitude: {
            score: defenses.fortitude.score,
        },
        Will: {
            score: defenses.will.score
        }
    };

    return (
        <div className="defense-score-container">
            {Object.keys(defenseObj).map((key, idx) => (
                <DefenseScore
                    key={`${key}-${idx}`}
                    scores={defenseObj[key]}
                    defense={key}
                    idx={idx}
                />
            ))}
            <DamageThreshold dt={defenses.fortitude.score} />
        </div>
    )
}

export default Defenses;