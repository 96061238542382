import React, {useState} from "react";

import "./FeatureAccordion.scss";
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Badge, Button,
    Input,
    Modal, ModalBody, ModalFooter,
    ModalHeader
} from "reactstrap";

const findFeatureInstance = (character, id) => {
    return character.features.find(f => f.id === id)
}
const findSkillId = (character, featureValue) => {
    return findFeatureInstance(character, featureValue)?.feature?.args?.skill;
}

const getValue = (character, feature) => {
    switch (feature?.choiceType) {
        case "skill":
            return findSkillId(character, feature.value);
        case "feat":
        case "talent":
            if (feature.value?.includes("-")) {
                const found = character.features.find(f => f.id === feature.value)?.feature?.id;
                return found;
            }
            return feature;
        case "weaponGroupId":
            if (feature.value?.includes("-")) {
                const profFeature = character.features.find(f => f.id === feature.value)
                if (profFeature?.feature?.args?.weaponGroupId) {
                    return profFeature.feature.args.weaponGroupId;
                }
            }
            return feature;
        default:
            return null;
    }
}

const getChoiceDetails = ({character, feature, classes, skills, feats, talents}) => {
    let value = getValue(character, feature);
    let options;
    switch(feature?.choiceType) {
        case "skill":
            const skillId = value;
            options = skills
                .map(s => ({...s.skill, ...s, name: `${s.skill.name} (${s.skill.abilityId})`}))
            if (feature.id === "feat.SkillTraining") {
                options = options
                    .filter(s => {
                        const isInClassSkills = s.classes.filter(c => character.classes.includes(c))?.length;
                        return (isInClassSkills && !s.trained) || (skillId?.length && s.id === skillId);
                    });
            } else {
                options = options
                    .filter(s => s.trained && (!s.focused || (skillId?.length && s.id === skillId)));
            }
            value = skillId;
            break;
        case "feat":
            const featInstance = findFeatureInstance(character, feature.value);
            if (feature.value?.includes("-")) {
                value = featInstance?.feature?.id;
            }
            let checkClassFeat = (_) => true;
            if (feature.id === "feature.ClassBonusFeat") {
                const classId = feature.args.class
                checkClassFeat = (f) => f.args.classes?.includes(classId);
            }

            let baseOptions = character.availableFeats;
            if (feature.args?.multiclass) {
                baseOptions = feature.args.options.map(o => feats.find(f => f.id === o));
            }

            const existingFeatIds = [...new Set(character.features
                .map(f => f.feature.id)
                .filter(id => id.startsWith("feat.")))];

            options = baseOptions
                .filter(s => {
                    if (!s.args?.multipleAllowed && existingFeatIds.includes(s.id) && value !== s.id) {
                        return false;
                    } else {
                        return value === s.id || (
                            checkClassFeat(s));
                    }
                })
                // remove any features where the selected feature is a preReq
                // TODO: use dependency tree
                .filter(s => {
                    return s.prereqs?.hasFeature?.id !== value
                });
            break;
        case "talent":
            const talentInstance = findFeatureInstance(character, feature.value);
            if (feature.value?.includes("-")) {
                value = talentInstance?.feature?.id;
            }

            const existingTalentIds = [...new Set(character.features
                .map(f => f.feature.id)
                .filter(id => id.startsWith("talent.")))];

            const classId = feature.args.class
            const baseTalents = character.availableTalentsByClass[classId]
            options = baseTalents
                .filter(s => {
                    if (!s.args?.multipleAllowed && existingTalentIds.includes(s.id) && value !== s.id) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .filter(s => {
                    // TODO: could dependency tree be used here too?
                    return s.prereqs?.hasFeature?.id !== value
                });
            break;
        case "weaponGroupId":
            const existingFocus = character.features.filter(f => f.feature.id === "feature.WeaponFocus")
                .map(f => f.feature.arg?.weaponGroupId);
            const existingProfs = character.proficiencies.weapon;

            if (feature.id === "feat.WeaponFocus") {
                options = existingProfs
                    .filter(f => f === value || !existingFocus.includes(f))
                    .map(w => ({ id: w, name: w }));
            } else if (feature.id === "feat.WeaponProficiency") {
                let baseOptions = ["Advanced", "Heavy", "Pistols", "Rifles", "Simple"];
                if (feature.parentFeature.id === "feature.ClassBonusFeat") {
                    const heroicClass = feature.parentFeature.args.class;
                    baseOptions = feature.args[heroicClass];
                } else if (feature.parentFeature.args?.multiclass) {
                    const multiclassOptions = feature.args?.multiclassOptions;
                    baseOptions = multiclassOptions[feature.parentFeature.args.class] || [];
                }

                options = baseOptions
                    .filter(f => f === value || !existingProfs.includes(f))
                    .map(w => ({ id: w, name: w }));
            }
            break;
        default:
            options = [];
            break;
    }
    return { value, options };
}

const ChoiceDescription = ({ choiceDetails }) => {
    if (!choiceDetails?.value) {
        return null;
    }

    const option = choiceDetails.options?.find(opt => opt.id === choiceDetails.value);

    if (option?.description) {
        return <span className="choice-description">{option.description}</span>
    }
    return null;
}

const ChoiceDropdown = ({ character, feature, choiceDetails, changeFeature, showChoices }) => {
    const [modal, setModal] = useState(false);
    const [confirmChanges, setConfirmChanges] = useState({});

    const openConfirmation = () => setModal(true);
    const closeConfirmation = () => {
        setConfirmChanges({});
        setModal(false);
    }
    const confirm = () => {
        changeFeature({...confirmChanges});
        closeConfirmation();
    }

    const featureType = feature?.choiceType;
    const hasChoice = ["feat", "talent", "skill", "weaponGroupId"].includes(featureType);

    if (!feature || !showChoices || !hasChoice) {
        return null;
    }

    const { value, options } = choiceDetails;

    const items = options
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(opt =>
            <option key={feature.id + "-" + opt.id} value={opt.id}>
                {opt.name}
            </option>
        );
    items.unshift(<option key={feature.id + "-nochoice"}/>);

    let dependencies = [];
    if (character.featureDependencies) {
        dependencies = Object.entries(character.featureDependencies)
            .filter(e => e[1].find(f => f.id === feature.uuid))
            .flatMap(e => {
                const found = character.features.find(f => f.id === e[0]);
                return {
                    id: found?.feature?.id?.split(".")?.slice(-1),
                    name: found?.feature?.name
                }
            });
    }
    return (
        <>
            <Input
                key={feature.id + "-input"}
                name={`Select a ${featureType}`}
                type="select"
                value={value || ''}
                onChange={(evt) => {
                    if (dependencies?.length) {
                        setConfirmChanges({
                            feature,
                            featureType,
                            value: evt.target.value });
                        openConfirmation();
                    } else {
                        changeFeature({
                            feature,
                            featureType,
                            value: evt.target.value
                        })
                    }
                }}
            >
                {items}
            </Input>
            <Modal isOpen={modal} toggle={closeConfirmation}>
                <ModalHeader toggle={closeConfirmation}>Warning</ModalHeader>
                <ModalBody>
                    Changing this will remove the following:
                    {dependencies.map(d => <ul key={d.id || "empty"}>{d.name}</ul>)}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirm}>
                        Change Anyway
                    </Button>{' '}
                    <Button color="secondary" onClick={closeConfirmation}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const FeatureAccordionItem = ({character, feature, showChoices, changeFeature, classes, skills, feats, talents }) => {
    const choiceDetails = getChoiceDetails({
        character, feature, classes, skills, feats, talents
    });

    let linkedFeature, linkedChoiceDetails;
    const selectedFeature = character.choices.find(choice => choice.id === feature?.value);

    if (selectedFeature && selectedFeature?.choiceType) {
        linkedFeature = {
            ...selectedFeature,
            ...selectedFeature.featureInstance.feature,
            parentFeature: feature,
            uuid: selectedFeature.featureInstance.id
        };
        linkedChoiceDetails = getChoiceDetails({
            character, feature: linkedFeature, classes, skills, feats, talents
        });
    }

    const showBadge = (f) => !f?.value && f?.choiceType;

    const featureChoiceClassNames = ["feature-choice"];
    if (linkedFeature) {
        featureChoiceClassNames.push("has-linked-choice");
    }

    return (
       <AccordionItem>
           <AccordionHeader targetId={feature.uuid}>
               <span>{feature.name}</span>
               {showBadge(feature) || showBadge(linkedFeature) ? <Badge pill>!</Badge> : null}
           </AccordionHeader>
           <AccordionBody accordionId={feature.uuid}>
               <div className="feature">
                   <span className="feature-description">{feature.description}</span>
                   <div className={featureChoiceClassNames.join(" ")}>
                       <ChoiceDropdown
                           character={character}
                           choiceDetails={choiceDetails}
                           feature={feature}
                           changeFeature={changeFeature}
                           showChoices={showChoices}
                           />
                       <ChoiceDescription character={character} choiceDetails={choiceDetails} />
                   </div>
                   <div className="feature-choice feature-subchoice">
                       <ChoiceDropdown
                           character={character}
                           choiceDetails={linkedChoiceDetails}
                           feature={linkedFeature}
                           parentFeature={feature}
                           changeFeature={changeFeature}
                           showChoices={showChoices}
                           />
                       <ChoiceDescription character={character} choiceDetails={linkedChoiceDetails} />
                   </div>
               </div>
           </AccordionBody>
       </AccordionItem>
   )
}

export default FeatureAccordionItem;