import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";
import {SpinnerPage} from "../common";

import * as Api from "../character/characterApi";
import {POINT_BUY_TABLE} from "../utils";

import {
    AbilityScoreAssignment,
    BuilderNav,
    CharacterFeatures,
    ClassesList,
    FeatTalentChoices,
    Home,
    SkillChoices,
    SpeciesList,
    TalentTrees
} from "./components";

import "./CharacterBuilderApp.scss";

const CharacterBuilderApp = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    if (id === undefined) {
        id = "1";
    }
    const [user, loading] = useAuthState(auth);
    const [character, setCharacter] = useState({});
    const [loadingCount, setLoadingCount] = useState(5);
    const [feats, setFeats] = useState([]);
    const [talents, setTalents] = useState([]);
    const [species, setSpecies] = useState([]);
    const [classes, setClasses] = useState([]);


    const showBadge = (page) => {
        switch(page) {
            case "Species":
                return character.species == null;
            case "Classes":
                return character.classes.length === 0;
            case "Abilities":
                const cost = Object.values(character.abilities)
                    .map(a => POINT_BUY_TABLE[a.ability.baseScore]).reduce((a, c) =>
                        a + c, 0);
                return cost < 25;
            case "Skills":
                return character.choices
                    .filter(c => !c.value?.length && c.choiceType === "skill")
                    .length > 0;
            case "Feats/Talents":
                const featChoices = character
                    .choices
                    .filter(c => !c.value?.length && (
                        c.choiceType === "feat" || c.choiceType === "weaponGroupId"));
                const talentChoices = character
                    .choices
                    .filter(c => !c.value?.length && c.choiceType === "talent");
                return (talentChoices.length + featChoices.length) > 0;
            default:
                return false;
        }
    }

    const submit = (lambda, details, next) => {
        const ch = (lambda && lambda(character)) || character;
        let promise;

        if (details?.length) {
            switch(details[0]) {
                case 'species':
                    promise = Api.changeSpecies(user.accessToken, ch.uuid, details[1]);
                    break;
                case 'class':
                    promise = Api.addClass(user.accessToken, ch.uuid, details[1]);
                    break;
                case 'choice':
                    promise = Api.makeChoice(user.accessToken, ch.uuid, details[1]);
                    break;
                default:
                    console.warn(`Unexpected details ${details[0]}`);
                    break;
            }
            promise.then(data => setCharacter(data));

        } else {
            promise = Api.save(user.accessToken, ch)
                .then(data => setCharacter(data))
        }
        if (promise && next) {
            promise.then(() => navigate(`../builder/${id}/${next}`));
        }
    }

    const addClass = (heroicClass) => {
        return Api.addClass(user.accessToken, character.uuid, heroicClass)
            .then(data => {
                setCharacter(data);
                return data;
            });

    }

    const removeClass = (heroicClass) => {
        return Api.removeClass(user.accessToken, character.uuid, heroicClass)
            .then(data => setCharacter(data));
    }

    useEffect(() => {
        if (user === null || user === undefined) {
            return;
        }

        Api.load(user.accessToken, id)
            .then(data => {
                setCharacter(data);
                setLoadingCount(p => p - 1);
            });
        Api.loadFeats(user.accessToken)
            .then(data => {
                setFeats(data.feats.map(f => ({ ...f, name: f.name.replace("feat.", "")})));
                setLoadingCount(p => p - 1);
            });
        Api.loadTalents(user.accessToken)
            .then(data => {
                setTalents({
                    ...data,
                    talents:
                        data.talents.map(f => ({...f, name: f.name.replace("talent.", "")})),
                });
                setLoadingCount(p => p - 1);
            });
        Api.loadClasses(user.accessToken)
            .then(data => {
                setClasses(data.classes);
                setLoadingCount(p => p - 1);
            });
        Api.loadSpecies(user.accessToken)
            .then(data => {
                setSpecies(data.species);
                setLoadingCount(p => p - 1);
            });
    }, [id, user]);

    if (loading) {
        return <SpinnerPage />;
    } else if (!user) {
        navigate("/login");
        return;
    } else if (loadingCount > 0) {
        return <SpinnerPage />;
    }

    const props = { character, setCharacter, submit, feats, talents, classes, species, token: user?.accessToken };

    return (
        <div className="character-builder-app">
            <BuilderNav character={character} showBadge={showBadge} />
            <div className="nav-spacer"/>
            <div className="builder-container">
                <Routes>
                    <Route path="/" element={<Home {...props} />} />
                    <Route path="/home" element={<Home {...props} />} />
                    <Route path="/species" element={<SpeciesList {...props} />} />
                    <Route path="/classes" element={<ClassesList {...props} addClass={addClass} removeClass={removeClass}/>} />
                    <Route path="/abilities" element={<AbilityScoreAssignment {...props} />} />
                    <Route path="/skills" element={<SkillChoices featureType="skill" {...props} />} />
                    <Route path="/feats" element={<CharacterFeatures featureType="feat" {...props} />} />
                    <Route path="/talents" element={<CharacterFeatures featureType="talent" {...props} />} />
                    <Route path="/feats-talents" element={<FeatTalentChoices {...props} />} />
                    <Route path="/tree" element={<TalentTrees {...props} />} />
                </Routes>
            </div>
        </div>
    );
}

export default CharacterBuilderApp;