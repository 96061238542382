export function modifier(score) {
    return formatBonus(Math.floor((score - 10) / 2));
}

export function formatBonus(m, showPlusOnZero = false) {
    if (m < 0) {
        return "-" + Math.abs(m);
    } else if (m === 0) {
        return showPlusOnZero ? ("+" + m) : m;
    } else {
        return "+" + m;
    }
}

export const POINT_BUY_TABLE = {
    8: 0,
    9: 1,
    10: 2,
    11: 3,
    12: 4,
    13: 5,
    14: 6,
    15: 8,
    16: 10,
    17: 13,
    18: 16
};

function importAll(r) {
    const images = {};
    r.keys().forEach(item =>
        images[item.replace('./', '')
            .replace(".png", "")
            .replace(".jpeg", "")
            .replace(".jpg", "")
            .replace(".webp", "")
            .replace(".svg", "")
        ] = r(item));
    return images;
}

export const Images = {
    species: importAll(require.context('./assets/species', false, /\.(png|jpe?g|svg|webp)$/)),
    classes: importAll(require.context('./assets/classes', false, /\.(png|jpe?g|svg|webp)$/)),
    talentTrees: importAll(require.context('./assets/talents/trees', false, /\.(png|jpe?g|svg|webp)$/)),
    talents: importAll(require.context('./assets/talents', false, /\.(png|jpe?g|svg|webp)$/)),
};
