import React from "react";

import "./CharacterFeatures.scss";
import { FeatureAccordion} from "./";

const CharacterFeatures = ({featureType, classes, feats, talents, character, submit}) => {
    const onMakeChoice = (data) => {
        submit(null, ['choice', data]);
    }

    const features = (character.choices || [])
        .filter( c => c.choiceType === featureType)
        .map(c => ({ ...c, ...c.featureInstance.feature, uuid: c.featureInstance.id }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const defaultOpen = features
        //open all the accordions that are missing their choice
        .filter(f => !f.value)
        .map(f => f.uuid);

    return (
        <div className="builder-features">
            <div className="character-feature-container">
                <FeatureAccordion
                    character={character}
                    features={features}
                    featureType={featureType}
                    defaultOpen={defaultOpen}
                    changeFeature={onMakeChoice}
                    classes={classes}
                    feats={feats}
                    talents={talents}
                    showChoices
                />
            </div>
        </div>
    );
}

export default CharacterFeatures;