import React from "react";

import "./SpeciesCard.scss";
import { FeatureAccordion } from "./";

import { Images } from '../../utils';

const SpeciesCard = ({ character, species, onChoose, chooseText }) => {
    if (!species) {
        return null;
    }

    const cta = onChoose ? <span className="cta" onClick={() => onChoose(species)}>{chooseText}</span> : null;

    const features = species.features.map(f => ({ ...f, uuid: f.name }));
    const defaultOpen = features.map(f => f.uuid);

    return (
        <div className="species-feature-container">
            <div className="species-header box">
                <div className="species-title">
                    <span className="species-name">{species.name}</span>
                    {cta}
                </div>
                <div className="species-details">
                    <span className="species-description">{species.description}</span>
                    <img src={Images.species[species.id.toLowerCase()]} alt={species.name}/>
                </div>

                <FeatureAccordion
                    character={character}
                    features={features}
                    defaultOpen={defaultOpen}
                />
            </div>
        </div>
    );
}

export default SpeciesCard;