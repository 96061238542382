import React from "react";

import "./AbilityScoreAssignment.scss";
import {Input} from "reactstrap";
import * as Api from "../../character/characterApi";
import {POINT_BUY_TABLE} from "../../utils";

const AbilityScoreAssignment = ({ character, setCharacter, token }) => {
    const scoreNames = {
        STR: "Strength",
        DEX: "Dexterity",
        CON: "Constitution",
        INT: "Intelligence",
        WIS: "Wisdom",
        CHA: "Charisma"
    };

    if (!character?.abilities || !Object.keys(character.abilities).length) {
        return <div>Something went wrong!</div>;
    }

    const baseScores = {};
    const scores = {}

    Object.entries(character.abilities).forEach(a => {
        baseScores[a[0]] = a[1].ability.baseScore;
        scores[a[0]] = a[1].score;
    });

    const pointsFn = (s) => Object.entries(s)
        .map(e => POINT_BUY_TABLE[e[1]])
        .reduce((partialSum, a) => partialSum + a, 0);
    const points = pointsFn(baseScores);

    const isValidScore = (opts) => {
        if (baseScores[opts.id] === opts.score) {
            return true;
        }
        const potentialScores = {...baseScores};
        potentialScores[opts.id] = opts.score;
        const potentialPoints = pointsFn(potentialScores);
        return (25 - potentialPoints) >= 0;
    }

    const remainingPoints = 25 - points;

    const optionsFor = (id) => {
        return Object.entries(POINT_BUY_TABLE)
            .filter(e => isValidScore({id, score: parseInt(e[0])}))
            .map(e => {
                const key = id + e[0];
                return (
                    <option key={key}>
                        {e[0]}
                    </option>
                )
            });
    }

    const scoreSelector = (id) => (
        <div key={`score-selector-${id}`} className="score-selector">
            <span>{scoreNames[id]}</span>
            <Input type="select"
               value={baseScores[id]}
               onChange={(evt) => {
                   character.abilities[id].ability.baseScore =
                       parseInt(evt.target.value);
                   Api.save(token, character)
                       .then(data => setCharacter(data))
               }}
            >;
                {optionsFor(id)}
            </Input>
            <span>Total: {scores[id]}</span>
        </div>
    );

    return (
        <div className="ability-score-assignment">
            <div className="points-assignment box filled-opaque">
                <h2>Ability Scores</h2>
                <span>Points Remaining</span>
                <span>{remainingPoints}/25</span>
                <div className="score-selector-row">
                    {Object.keys(scoreNames).map(id => scoreSelector(id))}
                </div>
            </div>
        </div>
    );
}

export default AbilityScoreAssignment;