import React, {useEffect, useState} from "react";
import {redirect, useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";

import * as Api from './characterApi';
import {SpinnerPage} from "../common";

import {Character, CharacterHeader} from "./components";

import "./CharacterSheetApp.scss";

const CharacterSheetApp = () => {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [character, setCharacter] = useState({});
    const [loadingCount, setLoadingCount] = useState(3);
    const [species, setSpecies] = useState([]);
    const [classes, setClasses] = useState([]);

    let { id } = useParams();

    useEffect(() => {
        if (!user) {
            return;
        }

        Api.load(user.accessToken, id)
            .then(data => {
                setCharacter(data);
                setLoadingCount(p => p - 1);
            });
        Api.loadClasses(user.accessToken)
            .then(data => {
                setClasses(data.classes);
                setLoadingCount(p => p - 1);
            });
        Api.loadSpecies(user.accessToken)
            .then(data => {
                setSpecies(data.species);
                setLoadingCount(p => p - 1);
            });
    }, [id, user]);

    if (id === undefined) {
        redirect("/");
        return;
    }

    if (loading || loadingCount > 0) {
        return <SpinnerPage />;
    }

    if (!user) {
        navigate("/login");
    }

    if (loadingCount > 0) {
        return <p>Loading...</p>;
    }

    if (!character?.abilities) {
        return <p>Something went wrong!</p>;
    }

    const saveNotes = (notes) => {
        return Api.saveNotes(user.accessToken, id, notes)
            .then(data =>
                setCharacter(data))
    }

    const addEquipment = (equipmentId) => {
        return Api.addEquipment(user.accessToken, id, equipmentId)
            .then(data =>
                setCharacter(data))
    }
    const toggleEquipment = (equipmentId, checked) => {
        return Api.toggleEquipment(user.accessToken, id, equipmentId, checked)
            .then(data =>
                setCharacter(data))
    }

    const removeEquipment = (equipmentId) => {
        return Api.removeEquipment(user.accessToken, id, equipmentId)
            .then(data => setCharacter(data));
    }

    return (
        <div className="character-sheet-app">
            <CharacterHeader character={character} species={species} classes={classes} />
            <div className="nav-spacer"/>
            <div className="character-sheet-container">
                <div className="character-sheet">
                    <Character
                        character={character}
                        species={species}
                        classes={classes}
                        setCharacter={setCharacter}
                        saveNotes={saveNotes}
                        addEquipment={addEquipment}
                        toggleEquipment={toggleEquipment}
                        removeEquipment={removeEquipment}
                    />
                </div>
            </div>
        </div>
    );
}

export default CharacterSheetApp;