import React from 'react';

import "./MyCharacters.scss";
import {Button, Card, CardBody, CardFooter, Nav, NavItem, NavLink} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";

const CharacterCard = ({ character, species, classes }) => {
    const { classes: classIds, name, species: speciesId } = character;
    let classString = "No class";
    let speciesString = "No species";
    const chSpecies = species.find(s => s.id === speciesId)
    if (chSpecies) {
        speciesString = chSpecies.name;
    }

    let level = 1;
    if (classIds?.length) {
        level = classIds.length;

        const counts = classIds?.reduce((p, c) => {
            if (!p.hasOwnProperty(c)) {
                p[c] = 0;
            }
            p[c]++;
            return p;
        }, {});

        const findName = (id) => classes.find(c => c.id === id)?.name
        classString = Object.entries(counts)
            .map(e => `${findName(e[0])} ${e[1]}`)
            .join(" / ");
    }

    const rawDescription = `Level ${level} | ${speciesString} | ${classString}`;
    const description = rawDescription.length > 32 ?
        rawDescription.slice(0,48) + "..." : rawDescription;

    const links = [
        {
            label: "View",
            to: `/character/${character.uuid}`
        },
        {
            label: "Edit",
            to: `/builder/${character.uuid}/home`
        }];
    return (
        <Card
            key={`ch-${character?.uuid}`}
            className="character-card box"
            color="info"
            outline>
            <CardBody>
                <div className="character-info">
                    <div className="img-border">
                        <img src="/swse.webp" alt="logo" />
                    </div>
                    <div className="basic-stats">
                        <span className="name">{name}</span>
                        <span className="description">{description}</span>
                    </div>
                </div>
            </CardBody>
            <CardFooter>
                <Nav pills>
                    {links.map(l =>(
                        <NavItem key={l.label}>
                            <NavLink>
                                <Link to={l.to} relative="path">{l.label}</Link>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </CardFooter>
        </Card>
    );
}

const MyCharacters = ({ characters, species, classes}) => {
    const navigate = useNavigate();
    if (!characters?.length) {
        return (
            <div className="no-characters">
                <div className="welcome-info box">
                    <h3>Welcome to SUSIE, your Star Wars Saga Edition companion!</h3>
                    <p>I'm glad you are here. Click the button below to start creating a character.</p>
                </div>
                <Button className="get-started-button" onClick={() => navigate("/builder/new")}>
                    Create Character
                </Button>
            </div>
        );
    }
    return (
        <div className="character-cards-list">
            {characters.map(character => CharacterCard({character, species, classes}))}
        </div>
    );
}

export default MyCharacters;