import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import LightSaber2 from "../assets/Lightsaber2.svg";
import LightSaber1 from "../assets/Lightsaber1.svg";
import {Link, useLocation} from "react-router-dom";

const Menu = ({character}) => {
    const { pathname } = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    let edit;
    if (pathname.startsWith("/character/")) {
        const id = pathname.split("/").slice(-1)[0];
        edit = (
            <DropdownItem>
                <Link to={`/builder/${id}/home`}
                      style={{color: "unset", textDecoration: 'none'}}>
                    Edit Character
                </Link>
            </DropdownItem>
        );
    }
    let view;
    if (pathname.startsWith("/builder") && character?.uuid) {
        view = (
            <DropdownItem>
                <Link to={`/character/${character.uuid}`}
                      style={{color: "unset", textDecoration: 'none'}}>
                    View Character
                </Link>
            </DropdownItem>
        );
    }
    let viewAll;
    if (pathname !== "/") {
        viewAll = (
            <>
                <DropdownItem divider />
                <DropdownItem>
                    <Link to={`/`} style={{ color: "unset", textDecoration: 'none' }}>My Characters</Link>
                </DropdownItem>
            </>
        );
    }

    return (
        <div className="menu">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="toggle">
                    <img src={LightSaber2} alt="menu"/>
                    <img src={LightSaber1} alt="menu"/>
                </DropdownToggle>
                <DropdownMenu container="body" className="dropdown-menu-dark">
                    <DropdownItem>
                        <Link to={`/builder/new`} style={{ color: "unset", textDecoration: 'none' }}>Create New Character</Link>
                    </DropdownItem>
                    {edit}
                    {view}
                    {viewAll}
                    <DropdownItem divider />
                    <DropdownItem>
                        <Link to={`/logout`} style={{ color: "unset", textDecoration: 'none' }}>Logout</Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default Menu;