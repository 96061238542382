import React, {useState} from "react";

import Xarrow from 'react-xarrows';
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Popover,
    PopoverBody,
    PopoverHeader
} from "reactstrap";

import AwarenessIcon from "../../assets/talents/trees/awareness.png";
import {Images} from "../../utils";

import "./TalentTrees.scss";

const TreeIcon = ({ data, id, name, icon, isActive, isHighlighted, onClick, onMouseEnter, onMouseLeave }) => {
    const classNames = ["icon-with-label", "box"];
    if (isActive) {
        classNames.push("active");
    }
    if (isHighlighted) {
        classNames.push("highlighted");
    }

    const treeSimpleId = data?.args?.tree?.toLowerCase() || id?.split(".")?.pop()?.toLowerCase();
    const iconSrc = icon || Images.talentTrees[treeSimpleId] || Images.talents[treeSimpleId] || AwarenessIcon;

    return (
            <div
                id={id}
                className={classNames.join(" ")}
                onClick={() => onClick && onClick()}
                onMouseEnter={() => onMouseEnter && onMouseEnter()}
                onMouseLeave={() => onMouseLeave && onMouseLeave()}
            >
                <img className="icon" src={iconSrc} alt={`${name} Talent Tree`} width="64px"/>
                <span className="label">{name}</span>
            </div>
    )
}

const TalentTreeList = ({ trees, selected, setSelected }) => {
    const [popovers, setPopovers] = useState({});
    console.log('trees', trees, trees.map(t => t.id))
    return (
        <div className="tree-icon-list">
            {trees.map(tree => {
                const id = tree.id.split(".").pop().toLowerCase();
                return (
                    <div key={`talent-tree-${id}`}>
                        <TreeIcon
                            key={id}
                            id={id}
                            isActive={selected.id === tree.id}
                            name={tree.name}
                            onClick={() => {
                                setSelected(tree);
                            }}
                            onMouseEnter={() => {
                                setPopovers(p => ({...p, [id]: true }));
                            }}
                            onMouseLeave={() => {
                                setPopovers(p => ({...p, [id]: false }));
                            }}
                        />
                        <Popover
                            key={`popover-${id}`}
                            className="tree-popover talent-popover"
                            placement="right"
                            target={id}
                            isOpen={popovers[id]}
                            toggle={() => setPopovers(p => ({...p, [id]: false }))}
                        >
                            <PopoverHeader>
                                {tree.name}
                            </PopoverHeader>
                            <PopoverBody>
                                {tree.description}
                            </PopoverBody>
                        </Popover>
                    </div>
                )}
            )}
        </div>
    );
}

const getLevelsAndEdges = (tree) => {
    const levels = [[...tree.children]];

    let group = tree.children;
    const edges = [
        ...group.flatMap(g => g.children.map(c => ({ from: g.id, to: c.id })))
    ];
    while (group.length) {
        group = group.flatMap(g => g.children);
        if (group.length) {
            const level = [];
            group.forEach(g => {
                if (!level.find(item => item.id === g.id)) {
                    level.push(g);
                }
            });

            levels.push(level);
            edges.push(...level.flatMap(g => g.children.map(c => ({ from: g.id, to: c.id }))));
        }
    }

    return { levels, edges };
}



const reverseTree = (node) => {
    const nodes = [node];
    if (node.parents?.length) {
        nodes.push(...node.parents.flatMap(reverseTree))
    }
    return nodes;
}

const TalentTree = ({ talents, character, tree, onClick }) => {
    const [popover, setPopover] = useState(null);
    console.log('tree', tree);
    const { levels, edges } = getLevelsAndEdges(tree);

    const reverseTrees = {};
    console.log('nodes', levels.flat());
    levels.flat().flat().forEach(n =>
        reverseTrees[n.id.split(".").pop().toLowerCase()] = reverseTree(n)
    );

    console.log('reverseTrees', levels, reverseTrees);

    const inTree = (id) => {
        const tree = popover && reverseTrees[popover]?.map(t => t.value || t.id);
        return id === popover || tree?.includes(id);
    }
    const color = (from, to) => {
        let color = "rgb(65,175,190)";

        if (inTree(from) && inTree(to)) {
            color = "red"
        }
        return color;
    }
    const isActive = (id) => !!character.features.find(f => f.feature.id === id);
    return (
        <>
            <div className="talent-tree-container">
                {levels.map((level, idx) =>
                    <div key={`tree-level-${idx}`} className="talent-tree-level">
                        {level.map(t => {
                            const data = talents.talents.find(talent => talent.id === t.id);
                            const id = t.id.split(".").pop().toLowerCase();
                            return (
                                <div key={id}>
                                    <TreeIcon
                                        data={data}
                                        id={id}
                                        key={id}
                                        name={t.title}
                                        icon={t.icon}
                                        isActive={isActive(t.id)}
                                        isHighlighted={inTree(t.id)}
                                        onMouseEnter={() => setPopover(id)}
                                        onClick={() => onClick(data)}
                                        // onMouseLeave={() => setPopover(null)}
                                    />
                                    <Popover
                                        className="talent-popover"
                                        placement="right"
                                        target={id}
                                        trigger="hover"
                                        isOpen={popover === id}
                                        toggle={() => setPopover(null)}
                                    >
                                        <PopoverHeader>
                                            {t.title}
                                        </PopoverHeader>
                                        <PopoverBody>
                                            {data.description}
                                        </PopoverBody>
                                    </Popover>
                                </div>
                            )}
                        )}
                    </div>

                )}
            </div>
            {edges.map(({from, to}) => {
                const labels = {};
                return (
                    <Xarrow
                        key={`arrow-${from}-${to}`}
                        start={from.split(".").pop().toLowerCase()}
                        end={to.split(".").pop().toLowerCase()}
                        endAnchor="top"
                        startAnchor="bottom"
                        color={color(from, to)}
                        strokeWidth={2}
                        labels={labels}
                    />
                )}
            )}
        </>
    )
}


const TalentTrees = ({ character, talents }) => {
    const [selected, setSelected] = useState(talents.trees[0] || null);
    const [modal, setModal] = useState(null);
    const closeModal = () => setModal(null);

    const selectedGraph = talents.treeGraphs.find(g => g[0].id === selected.id);

    return (
        <div className="talent-trees-root">
            <TalentTreeList
                trees={talents.trees}
                selected={selected}
                setSelected={setSelected}
            />
            <TalentTree
                talents={talents}
                graph={selectedGraph}
                tree={selectedGraph[0]}
                character={character}
                onClick={setModal}
            />
            <Modal isOpen={!!modal} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>{modal?.name || modal?.title}</ModalHeader>
                <ModalBody>
                    {modal?.description}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => {}}>
                        OK
                    </Button>{' '}
                    <Button color="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TalentTrees;