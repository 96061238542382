import React, {useState} from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Actions, Features, Notes } from "./";

import './TabbedFeatures.scss';

const TabbedFeatures = ({ character, setCharacter, saveNotes }) => {
    const [currentActiveTab, setCurrentActiveTab] = useState('actions');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const className = tab => {
        if (currentActiveTab === tab) {
            return "active";
        }
        return "";
    }

    return (
        <div className="tab-panel-container">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={className("actions")}
                        onClick={() => toggle("actions")}
                    >
                        Actions
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={className("features")}
                        onClick={() => toggle("features")}
                    >
                        Features & Traits
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={className("notes")}
                        onClick={() => toggle("notes")}
                    >
                        Notes
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab} className="tab-panel-content box">
                <TabPane tabId="actions">
                   <Actions />
                </TabPane>
                <TabPane tabId="features">
                    <Features features={character.features} />
                </TabPane>
                <TabPane tabId="notes">
                    <Notes
                        character={character}
                        setCharacter={setCharacter}
                        saveNotes={saveNotes}
                    />
                </TabPane>
            </TabContent>
        </div>
    );
}

export default TabbedFeatures;