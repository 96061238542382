import {useCallback, useEffect} from "react";
import debounce from 'lodash.debounce';

function Autosave({ data, saveData, delayMillis }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = useCallback(
        debounce(async (d) => {
                await saveData(d);
            },
        delayMillis || 500),
        [],
    );

    // The magic useEffect hook. This runs only when `experimentData.name` changes.
    // We could add more properties, should we want to listen for their changes.
    useEffect(() => {
        if (data) {
            debouncedSave(data);
        }
        // debouncedSave is wrapped in a useCallback with an empty dependency list,
        // thus it will not change and in turn will not re-trigger this effect.
    }, [data, debouncedSave]);
    // Do not display anything on the screen.
    return null;
}

export default Autosave;