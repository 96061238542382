import React from "react";

import {Button, ButtonGroup, Input} from "reactstrap";
import {useNavigate} from "react-router-dom";

import * as Api from "../../character/characterApi";

import "./Home.scss";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase";

const Home = ({ character, setCharacter }) => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();

    const nextClick = async () => {
        await Api.save(user.accessToken, character)
            .then(data => {
                setCharacter(data);
                return data;
            })
            .then((data) => navigate(`/builder/${data.uuid}/species`))
    }

    return (
        <div className="builder-home">
            <div className="builder-content box filled-opaque">
                <div className="name-row">
                    <div className="input-with-label">
                        <div className="label">
                            <span>Character Name</span>
                        </div>
                        <div className="input">
                            <Input
                                value={character.name}
                                className="character-name-input"
                                onChange={e => setCharacter(p => ({...p, name: e.target.value }))}
                            />
                            <Button
                                disabled={!character?.name?.length}
                                onClick={() => nextClick()}
                            >
                                Next
                            </Button>
                        </div>

                    </div>
                </div>
                <ButtonGroup className="gender-group">
                    <Button
                        color="primary"
                        outline
                        onClick={() => setCharacter(p => ({...p, gender: "nb" }))}
                        active={["nb", "non-binary", "non_binary", "nonbinary"]
                            .includes(character.gender?.toLowerCase())}
                    >
                        Non-Binary
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => setCharacter(p => ({...p, gender: "Male" }))}
                        active={character.gender?.toLowerCase() === "male"}
                    >
                        Male
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => setCharacter(p => ({...p, gender: "Female" }))}
                        active={character.gender?.toLowerCase() === "female"}
                    >
                        Female
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default Home;