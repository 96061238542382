import React from 'react';

import './Features.scss';
import {Table} from "reactstrap";

const Features = ({ features }) => {
    const displayable = features.filter(f =>
        f.feature.id.startsWith("feat.") ||
        f.feature.id.startsWith("talent.")
    )
        .map(f => ({
            id: f.id,
            name: f.feature.name,
            description: f.feature.description,
            source: f.sourceId
        }));
    return (
        <div className="features-container">
            <Table className="features-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                {displayable.map(f =>
                    <tr key={f.id}>
                        <td>{f.name}</td>
                        <td>{f.description}</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default Features;