import React, {useState} from "react";

import { SpeciesCard } from "./";

import {
    Carousel,
    CarouselControl,
    CarouselItem
} from "reactstrap";

import "./SpeciesList.scss";

const SpeciesList = ({ character, setCharacter, submit, species }) => {
    const chSpecies = character.species?.length && species.find(s => s.id === character.species)

    const [selectedSpecies, setSelectedSpecies] =
        useState(chSpecies ? species.find(s => s.id === chSpecies.id) : species[0]);
    const [activeIndex, setActiveIndex] =
        useState(chSpecies ? species.findIndex(s => s.id === chSpecies.id) : 0);
    const [animating, setAnimating] = useState(false);

    const onSubmit = () => {
        const lambda = (ch) => ({ ...ch, species: selectedSpecies.id });
        setCharacter(lambda);
        submit(lambda, ['species', selectedSpecies.id], "classes");
    }
    const text = (sp) => {
        if (!chSpecies) {
            return "Choose Species";
        } else if (sp?.id === chSpecies.id) {
            return null;
        } else {
            return "Change Species";
        }
    }

    const canChoose = (sp) => {
        return !chSpecies || sp?.id !== chSpecies.id;
    }

    const slides = species.map((item) => {
        return (
            <CarouselItem
                className="species-carousel-item"
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <SpeciesCard
                    character={character}
                    species={item}
                    onChoose={canChoose(item) ? onSubmit : null}
                    chooseText={text(item)}
                />
            </CarouselItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === species.length - 1 ? 0 : activeIndex + 1;
        setSelectedSpecies(species[nextIndex]);
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? species.length - 1 : activeIndex - 1;
        setSelectedSpecies(species[nextIndex]);
        setActiveIndex(nextIndex);
    };

    return (
        <div className="builder-species">
            <div className="species-list-container">
                <div className="builder-species-list">
                    <Carousel
                        interval={false}
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        {slides}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                        />
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default SpeciesList;