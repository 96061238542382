import React from 'react';

import { BoxWithTotal } from "./index";

import './StackBoxes.scss';

const StackBoxes = (props) => {
    const { boxes } = props;
    return (
        <div className="stack-box-container">
            {boxes.map((b, i) => (
                <BoxWithTotal key={`${b.label}-${i}`} {...b} />
            ))}
        </div>
    );
}

export default StackBoxes;