import React from 'react';

import './DamageThreshold.scss';

const DamageThreshold = ({ dt }) => {
    return (
        <div className="dt-box">
            <span className="dt-total">{dt}</span>
            <span className="dt-label">DT</span>
        </div>
    );
}

export default DamageThreshold;