import React from 'react';
import * as utils from '../../utils';

import './Skills.scss';
import {Table} from "reactstrap";

const Skills = (props) => {
    const { skills } = props;
    const sorted = Object.values(skills)
        .sort((a, b) => a.skill.name.localeCompare(b.skill.name))
    const skillDot = (skill) => {
        const trainedClass = skill.trained ?
            (skill.focused ? "dot-focused" : "dot-trained") :
            "";
        return <span className={`trained dot ${trainedClass}`} />;
    }

    return (
        <div className="skills-container box">
            <Table className="skills-table">
                <tbody>
                    {sorted.map(a =>
                        <tr key={a.skill.name}>
                            <td>{skillDot(a)}</td>
                            <td>
                                <span className="skill-key-ability">{a.skill.abilityId}</span>
                            </td>
                            <td>
                                <span className="skill-name">{a.skill.name}</span>
                            </td>
                            <td>
                                <span className="skill-bonus">
                                    {utils.formatBonus(a.modifier)}
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

export default Skills;