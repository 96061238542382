import {
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    createUserWithEmailAndPassword
} from "firebase/auth";
import { auth } from "../firebase";
import {Exception} from "sass";
import {redirect} from "react-router-dom";
import {TreeGraph} from "../common/Graph";

export async function logout() {
    await signOut(auth);
}

export async function login({ username: email, password }) {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            return user.accessToken;
        })
        .catch(({ message: errorMessage }) => {
            console.error('Firebase sign-in error', errorMessage);
            return async () => { return [null, errorMessage]; }
        });
}

export async function loginWithPopupProvider(providerClass) {
    return signInWithPopup(auth, new providerClass())
        .then(result => {
            const credential = providerClass.credentialFromResult(result);
            const user = result.user;
            console.log('signinWithPopop success', user, credential);
            return user.accessToken;
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData?.email;
            // The AuthCredential type that was used.
            const credential = providerClass.credentialFromError(error);

            console.error(`Firebase sign-in error code=${errorCode} message=${errorMessage} email=${email}`, credential);
            return async () => { return [null, errorMessage]; }
        });
}

export async function register({ email, password}) {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up
            const user = userCredential.user;
            return user.getIdToken();
        })
        .catch(({ code: errorCode, message: errorMessage }) => {
            console.error('Firebase create error', errorCode, errorMessage);
        });
}

export async function validateToken(token) {
    return await fetch(process.env.REACT_APP_BACKEND_HOST + `/api/v1/status`, {
        method: 'POST',
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.status >= 200 && response.status < 300;
    });
}

export function load(token, id) {
    const path = `/api/v2/character/swse/character/${id}`;
    console.log(`Calling fetch ${path}`, token);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw new Exception();
        }
        return response.json();
    })
    .catch(e => {
        console.log(e);
        redirect("/error");
    })
}

export function loadAll(token) {
    const path = `/api/v2/character/swse/character`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json());
}

export function loadFeats(token) {
    const path = `/api/v2/character/swse/feats`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
}

export function loadTalents(token) {
    const path = `/api/v2/character/swse/talents`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
        .then(data => {
            const treeGraphs = data.trees.map(tree => toGraph(tree, data.talents))
            return { ...data, treeGraphs };
        })

}

function toGraph(tree, talents) {
    const graph = new TreeGraph(tree.id, tree.name);

    tree.features.forEach(talent => {
        graph.add(talent.id);
        if (talent.prereqs?.hasFeature && talent.prereqs.hasFeature.id.startsWith("talent.")) {
            graph.addRelationship(
                talent.prereqs.hasFeature.id,
                talent.id
            );
        } else if (talent.prereqs?.hasFeatures?.length) {
            talent.prereqs.hasFeatures.filter(f => f.id.startsWith("talent.")).forEach(prereq => {
                graph.addRelationship(
                    prereq.id,
                    talent.id
                );
            })
        }
    });
    const lookup = (node) => {
        return talents.find(f => f.id === node.value);
    }

    return [{
        id: graph.id,
        title: graph.name,
        tooltip: {
            content: graph.name
        },
        parents: [],
        children: graph.getRootNodes().map(node => toBstData(node, lookup))
    }];
}

function toBstData(node, nodeMapper) {
    const feature = nodeMapper(node);
    return {
        id: feature.id,
        title: feature.name,
        tooltip: {
            content: feature.name
        },
        feature,
        parents: node.parents,
        children: node.children.map(n => toBstData(n, nodeMapper))
    }
}

export function loadSpecies(token) {
    const path = `/api/v2/character/swse/species`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
}

export function loadClasses(token) {
    const path = `/api/v2/character/swse/class`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
}

export function loadEquipment(token) {
    const path = `/api/v2/character/swse/equipment`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
}

export function changeSpecies(token, id, species) {
    let path = `/api/v2/character/swse/character/${id}/species`;
    let method = "PUT"
    console.log(`Calling fetch ${method} ${path}`, species);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: species })
        })
        .then(response => response.json());
}

export function addClass(token, id, heroicClass) {
    let path = `/api/v2/character/swse/character/${id}/class`;
    let method = "PUT"
    console.log(`Calling fetch ${method} ${path}`, heroicClass);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: heroicClass.id})
        })
        .then(response => response.json());
}

export function removeClass(token, id, heroicClass) {
    let path = `/api/v2/character/swse/character/${id}/class/${heroicClass.id}`;
    let method = "DELETE"
    console.log(`Calling fetch ${method} ${path}`, heroicClass);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json());
}

export function makeChoice(token, id, {feature, featureType, value}) {
    let path = `/api/v2/character/swse/character/${id}/choice`;
    let method = "PUT"

    console.log(`Calling fetch ${method} ${path}`, feature, value);
    const body = {
        choiceType: featureType,
        choiceId: feature.featureInstance.id,
        id: value
    };
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json());
}

export function saveNotes(token, uuid, notes) {
    const path = `/api/v2/character/swse/character/${uuid}/notes`;
    const method = "PUT";
    console.log(`Calling fetch ${method} ${path}`, notes);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ notes })
        })
        .then(response => response.json());
}

export function addEquipment(token, uuid, equipmentId) {
    const path = `/api/v2/character/swse/character/${uuid}/equipment`;
    const method = "POST";
    console.log(`Calling fetch ${method} ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ equipmentId })
        })
        .then(response => response.json());
}

export function toggleEquipment(token, uuid, equipmentId, checked) {
    const path = `/api/v2/character/swse/character/${uuid}/equipment/${equipmentId}`;
    const method = "PUT";
    console.log(`Calling fetch ${method} ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ equipped: checked })
        })
        .then(response => response.json());
}

export function removeEquipment(token, uuid, equipmentId) {
    const path = `/api/v2/character/swse/character/${uuid}/equipment/${equipmentId}`;
    const method = "DELETE";
    console.log(`Calling fetch ${method} ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json());
}

export function save(token, character){
    let path = "/api/v2/character/swse/character";
    let method = "POST";
    if (character.uuid?.length) {
        path += "/" + character.uuid;
        method = "PUT";
    }
    console.log(`Calling fetch ${method} ${path}`, character);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path,
        {
            method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ...character })
        })
        .then(response => response.json());
}