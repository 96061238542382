import React from 'react';

import './HitPoints.scss';

const HitPoints = ({ hitPoints }) => {
    return (
        <div className="hp-box-container">
            <div className="hp-box">
                <span className="hp-total">{hitPoints.max} / {hitPoints.max}</span>
                <span className="hp-label">Hit Points</span>
            </div>
        </div>
    );
}

export default HitPoints;