import React, {useEffect, useState} from 'react';

import './Inventory.scss';
import {Button, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Spinner, Table} from "reactstrap";
import {formatBonus} from "../../utils";

import { ReactComponent as Trash } from "../../assets/trash.svg";
import * as Api from "../characterApi";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase";

const Inventory = ({equipment, addEquipment, toggleEquipment, removeEquipment}) => {
    const [user] = useAuthState(auth);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [equipmentList, setEquipmentList] = useState([]);

    useEffect(() => {
        if (!user) {
            return;
        }

        Api.loadEquipment(user.accessToken)
            .then(data => {
                setEquipmentList(data);
                setLoading(false);
            });
    }, [user]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const buildTable = (chEquipment) => (
        <Table className="equipment-table">
            <thead>
            <tr>
                <th></th>
                <th>TYPE</th>
                <th>NAME</th>
                <th>ATTACK</th>
                <th>DAMAGE</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {chEquipment.map(e => ({...e.equipment, ...e })).map(e =>
                <tr key={e.id}>
                    <td>
                        <Input type="checkbox" checked={e.equipped} onChange={(evt) => toggleEquipment(e.id, evt.target.checked)}/>
                    </td>
                    <td>{e.weaponGroupId}</td>
                    <td>{e.name}</td>
                    <td>{e.attack ? formatBonus(e.attack) : ""}</td>
                    <td>{e.damage}</td>
                    <td>
                        <Button className="delete-button" onClick={() => removeEquipment(e.id)}>
                            <Trash className="icon" />
                        </Button>
                    </td>
                </tr>
            )}
            <tr className="no-border">
                <td>
                    <Button className="add-button" onClick={handleShow}>
                        Add
                    </Button>
                </td>
                <td />
                <td />
                <td />
                <td />
            </tr>
            </tbody>
        </Table>
    );

    let content = <Spinner />;
    if (!loading) {
        content = (
            <div className="equipment-list">
                <Table className="equipment-table">
                    <thead>
                    <tr>
                        <th>TYPE</th>
                        <th>NAME</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {equipmentList?.equipment?.map(a =>
                        <tr key={a.id}>
                            <td>WEAPON</td>
                            <td>{a.name}</td>
                            <td>
                                <Button className="add-button" onClick={() => addEquipment(a.id)
                                    .then(handleClose)}>
                                    Add
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div className="inventory-container">
            <div className="inventory box">
                {buildTable(equipment)}
                <Offcanvas isOpen={show} className="inventory-canvas">
                    <OffcanvasHeader className="canvas-header" toggle={handleClose}>
                        Add Equipment
                    </OffcanvasHeader>
                    <OffcanvasBody className="canvas-body">
                        {content}
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </div>
    );
}

export default Inventory;