import React from "react";

import { Menu } from "../../common";

import './CharacterHeader.scss';
const CharacterHeader = ({character, species, classes}) => {
    const { classes: classIds, name, species: speciesId, gender } = character;
    let classString = "No class";
    let speciesString = "No species";
    const chSpecies = species.find(s => s.id === speciesId)
    if (chSpecies) {
        speciesString = chSpecies.name;
    }

    let lvl = 1;
    if (classIds?.length) {
        lvl = classIds.length;
        const counts = classIds?.reduce((p, c) => {
            if (!p.hasOwnProperty(c)) {
                p[c] = 0;
            }
            p[c]++;
            return p;
        }, {});

        const findName = (id) => classes.find(c => c.id === id)?.name
        classString = Object.entries(counts)
            .map(e => `${findName(e[0])} ${e[1]}`)
            .join(" / ");
    }

    const level = `Level ${lvl}`;
    const description = `${gender} ${speciesString} ${classString}`;

    return (
        <header className="character-header-container">
            <div className="header">
                <div className="img-border">
                    <img src="/swse.webp" alt="logo" />
                </div>
                <div className="basic-stats">
                    <span className="name">{name}</span>
                    <span className="description">{description}</span>
                    <span className="level">{level}</span>
                </div>
                <Menu character={character}/>
            </div>
        </header>
    );
}

export default CharacterHeader;