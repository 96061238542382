import React from 'react';
import {Badge, Nav, NavItem, NavLink} from "reactstrap";
import {Link, useLocation} from "react-router-dom";

import "./BuilderNav.scss";
import {Menu} from "../../common";

const BuilderNav = ({character, showBadge }) => {
    const location = useLocation();

    const hasSpeciesAndClass = character.species != null && !!character.classes.length;

    const path = (s) => s.replace("/", "-")
    const isActive = (s) => location.pathname.endsWith(`/${path(s)}`);
    const links = ["Home", "Species", "Classes", "Abilities", "Skills", "Feats/Talents"].map(label => ({
        label: Array.isArray(label) ? label[0] : label,
        to: "../" + path(Array.isArray(label) ? label[1] :label.toLowerCase()),
        active: isActive((Array.isArray(label) ? label[1] : label).toLowerCase()),
        disabled: label === "Skills" || label === "Feats/Talents" ?
            !hasSpeciesAndClass : false,
        showBadge: ((label === "Skills" || label === "Feats/Talents") && !hasSpeciesAndClass) ?
            false : showBadge(label)
    }));

    return (
        <div className="nav-container">
            <div className="spacer" />
            <Nav pills>
                {links.map(l =>(
                    <NavItem key={l.label}>
                        <NavLink active={l.active} tag="div" disabled={l.disabled}>
                            <Link to={l.to} relative="path">{l.label}</Link>
                            {l.showBadge ? <Badge pill>!</Badge> : null}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <Menu character={character} />
        </div>
    );
}

export default BuilderNav;