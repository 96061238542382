import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

import Error from "./Error";
import { CharacterSheetApp } from "./character";
import { CharacterBuilderApp, MyCharacterListApp, NewCharacterApp } from "./builder";
import Login from "./login/Login";
import Register from "./login/Register";
import Logout from "./login/Logout";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MyCharacterListApp />,
        errorElement: <Error />
    },
    {
        path: "/login",
        element: <Login standalone />,
        errorElement: <Error />
    },
    {
        path: "/logout",
        element: <Logout />,
        errorElement: <Error />
    },
    {
        path: "/register",
        element: <Register />,
        errorElement: <Error />
    },
    {
        path: "/character/:id",
        element: <CharacterSheetApp />,
        errorElement: <Error />
    },
    {
        path: "/builder/new",
        element: <NewCharacterApp />,
        errorElement: <Error />
    },
    {
        path: "/builder/:id/*",
        element: <CharacterBuilderApp />,
        errorElement: <Error />
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
