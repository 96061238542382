import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";
import {SpinnerPage} from "../common";
import { Home } from "./components";

import "./CharacterBuilderApp.scss";

const NewCharacterApp = () => {
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate();
    const [character, setCharacter] = useState({ name: "", gender: "nb" });

    if (!loading && !user) {
        navigate("/login");
        return null;
    } else if (loading) {
        return <SpinnerPage />;
    }

    if (character?.uuid) {
        navigate(`/builder/${character.uuid}/home`, { replace: true });
        return null;
    }

    const props = { character, setCharacter };

    return (
        <div className="character-builder-app">
            <div>
                <div className="app-inner">
                    <div className="welcome box filled-opaque">
                        <span>Create New Character</span>
                    </div>
                    <Home isNewCharacter {...props} />
                </div>
            </div>
        </div>
    );
}

export default NewCharacterApp;