import React from 'react';

import './BoxWithTotal.scss';

const BoxWithTotal = (props) => {
    const { total, label } = props;
    return (
        <div className="fp-container">
            <div className="fp-box">
                <span className="fp-total">{total}</span>
            </div>
            <span className="fp-label">{label}</span>
        </div>
    );
}

export default BoxWithTotal;