import React from "react";

import "./SkillChoices.scss";
import {CharacterFeatures} from "./";

const SkillChoices = (props) => {
    return (
        <div className="skill-choices-container">
            <div className="header box">
                <div className="title">
                    <span className="name">Skills</span>
                </div>
                <CharacterFeatures featureType="skill" {...props} />
            </div>
        </div>
    );
}

export default SkillChoices;