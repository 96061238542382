import React from 'react';
import * as utils from '../../utils';

import './AbilityScores.scss';

const AbilityScore = (props) => {
    const { ability, score } = props;
    return (
        <div className="score-container">
            <div className="image-with-score ability-score" >
                <div>
                    <span className="ability">{ability}</span>
                    <span className="score">{score}</span>
                </div>
            </div>
            <div className="circle-raw-score">
                <span className="modifier">{utils.modifier(score)}</span>
            </div>
        </div>
    );
}

const AbilityScores = (props) => {
    const { abilities } = props;
    const fixAbilities = {
        STR: abilities.STR,
        DEX: abilities.DEX,
        CON: abilities.CON,
        INT: abilities.INT,
        WIS: abilities.WIS,
        CHA: abilities.CHA
    }
    return (
        <div className="ability-score-container">
            {Object.values(fixAbilities).map((ab, idx) => (
                <AbilityScore
                    key={`sheet-score-${ab.ability.id}-${idx}`}
                    score={ab.score}
                    ability={ab.ability.id}
                    idx={idx}
                />
            ))}
        </div>
    )
}

export default AbilityScores;