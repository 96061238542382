import React, {useState} from 'react';

import './Actions.scss';
import {Nav, NavItem, NavLink, TabContent, Table, TabPane} from "reactstrap";

const Actions = (props) => {
    const [currentActiveTab, setCurrentActiveTab] = useState('standard');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const className = tab => {
        if (currentActiveTab === tab) {
            return "active";
        }
        return "";
    }

    const fullRoundActions = [
        {
            name: "Coup de Grace",
            description: "You automatically score a Critical Hit against a Helpless target, dealing double damage."
        },
        {
            name: "Full Attack",
        },
        {
            name: "Run",

        }
    ];
    const standardActions = [
        {
            "name": "Attack (Melee)",
            "description": ""
        },
        {
            "name": "Attack (Ranged)",
            "description": ""
        },
        {
            "name": "Aid Another",
            "description": ""
        },
        {
            "name": "Attack an Object",
            "description": ""
        },
        {
            "name": "Charge",
            "description": ""
        },
        {
            "name": "Disarm",
            "description": ""
        },
        {
            "name": "Fight Defensively",
            "description": ""
        },
        {
            "name": "Grab",
            "description": ""
        },
        {
            "name": "Grapple",
            "description": ""
        }
    ];
    const swiftActions = [
        {
            "name": "Activate an Item",
            "description": ""
        },
        {
            "name": "Aim",
            "description": ""
        },
        {
            "name": "Catch a Second Wind",
            "description": ""
        },
        {
            "name": "Drop an Item",
            "description": ""
        },
        {
            "name": "Fall Prone",
            "description": ""
        },
        {
            "name": "Recover",
            "description": ""
        },
        {
            "name": "Switch Weapon Mode",
            "description": ""
        }
    ];

    const buildTable = (actions) => (
        <Table className="actions-table">
            <tbody>
            {actions.map(a =>
                <tr key={a.name}>
                    <td>{a.name}</td>
                    <td>{a.description}</td>
                </tr>
            )}
            </tbody>
        </Table>
    );

    return (
        <div className="actions-container">
            <Nav pills>
                {["standard", "swift", "full-round"].map(i =>
                    <NavItem key={i}>
                        <NavLink
                            className={className(i)}
                            onClick={() => toggle(i)}
                        >
                            {i.toUpperCase()}
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            <TabContent activeTab={currentActiveTab} className="actions-tab-panel-content">
                <TabPane tabId="standard">
                    {buildTable(standardActions)}
                </TabPane>
                <TabPane tabId="swift">
                    {buildTable(swiftActions)}
                </TabPane>
                <TabPane tabId="full-round">
                    {buildTable(fullRoundActions)}
                </TabPane>
            </TabContent>
        </div>
    );
}

export default Actions;