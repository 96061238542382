export class Node {
    constructor(value) {
        this.value = value;
        this.children = [];
        this.parents = [];
    }

    addParent(node) {
        this.parents.push(node);
    }
    addChild(node) {
        this.children.push(node);
    }

    removeAdjacent(node) {
        let index;
        index = this.parents.indexOf(node);
        if (index > -1) {
            this.parents.splice(index, -1);
        }
        index = this.children.indexOf(node);
        if (index > -1) {
            this.parents.splice(index, -1);
        }

        return node;
    }

    getParents() {
        return this.parents;
    }
    getChildren() {
        return this.children;
    }
}

export class TreeGraph {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.nodes = new Map();
    }

    getRootNodes() {
        return Array.from(this.nodes.values())
            .filter(n => !n.parents.length);
    }

    add(value) {
        if (this.nodes.has(value)) {
            // just return vertex if it already exists
            return this.nodes.get(value);
        }
        const vertex = new Node(value);
        this.nodes.set(value, vertex);
        return vertex;
    }

    remove(value) {
        const current = this.nodes.get(value);
        if (current) {
            for (const node of this.nodes.values()) {
                node.removeAdjacent(node);
            }
        }
        return this.nodes.delete(value);
    }

    addRelationship(parent, child) {
        const parentNode = this.add(parent);
        const childNode = this.add(child);

        parentNode.addChild(childNode);
        childNode.addParent(parentNode);
    }
}