import React from 'react';

import './Initiative.scss';
import {formatBonus} from "../../utils";

const LargeBoxWithScore = ({ skill }) => {
    const score = formatBonus(skill?.modifier || 0);
    return (
        <div className="init-box-container">
            <div className="init-box">
                <span className="init-total">{score}</span>
                <span className="init-label">{skill.skill.name}</span>
            </div>
        </div>
    );
}

export default LargeBoxWithScore;